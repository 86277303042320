<template>
    <Post
        :delete-post="deletePost"
        :edit-post="editPost"
        :post="post"
        class="single-view-post"
    >
        <template #comment-container>
            <div class="comment-container">
                <div class="load-more-buttons post-comment-show-parents mbottom-medium">
                    <template v-if="mode === 'comment' && post.overallCommentCount > 1">
                        <app-button
                            :busy="isRoutingToLinkToPost"
                            class="comment-action reply-action"
                            @click="routeToLinkToPost"
                        >
                            {{ $t("show_all_comments") }}
                        </app-button>
                    </template>
                    <template v-if="mode === 'comment' && !parentCommentsShown && !hasCommentOpenedViaDirectLinkParents">
                        <app-button
                            :busy="isFetchingParents"
                            class="comment-action reply-action"
                            @click="onClickOnShowParentsBtn"
                        >
                            {{ $t("show_parent_comments") }}
                        </app-button>
                    </template>
                </div>
                <CommentSection
                    :comment-count="post.commentCount"
                    :comments="post.comments"
                    :content-creator-uid="post.creatorUid"
                    :content-uid="post.uid"
                    :selected-reaction-type="post.currentUsersReaction"
                    :total-reactions="post.reactions"
                    content-type="post"
                />
            </div>
        </template>
    </Post>
</template>

<script>
import {
    mapActions,
    mapGetters,
    mapState,
} from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { POST_MODULE_NAME } from "@web/store/post/post";
import {
    DELETE_POST,
    EDIT_POST,
    EXPAND_COMMENT_PARENTS,
} from "@web/store/post/single-post/actions";
import { SINGLE_POST_MODULE_NAME } from "@web/store/post/single-post/single-post";
import { IS_SHOWN_COMMENT_ROOT_COMMENT } from "@web/store/post/single-post/getters";
import Post from "@web/views/posts/Post";
import CommentSection from "@web/components/comments/CommentSection";

/**
 * implementation of Post with pagination suitable for viewing a single post (access via direct url)
 */
export default {
    name: "SingleViewPost",
    components: {
        CommentSection,
        Post,
    },
    props: {
        /** @type {EverestPost} */
        post: Object,
    },
    data() {
        return {
            parentCommentsShown: false,
            isFetchingParents: false,
            isRoutingToLinkToPost: false,
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
        ...mapState(POST_MODULE_NAME + SINGLE_POST_MODULE_NAME, ["mode"]),
        ...mapGetters({
            hasCommentOpenedViaDirectLinkParents:
                POST_MODULE_NAME + SINGLE_POST_MODULE_NAME + IS_SHOWN_COMMENT_ROOT_COMMENT,
        }),
        directRouterLinkToPost() {
            return `/intranet/${this.intranet.uid}/post/id/${this.post.uid}`;
        },
    },
    methods: {
        ...mapActions({
            editPost: POST_MODULE_NAME + SINGLE_POST_MODULE_NAME + EDIT_POST,
            deletePost: POST_MODULE_NAME + SINGLE_POST_MODULE_NAME + DELETE_POST,
            expandCommentParents: POST_MODULE_NAME + SINGLE_POST_MODULE_NAME + EXPAND_COMMENT_PARENTS,
        }),
        onDelete() {
            return this.deletePost();
        },
        async onClickOnShowParentsBtn() {
            this.isFetchingParents = true;
            await this.expandCommentParents();
            this.isFetchingParents = false;
            this.parentCommentsShown = true;
        },
        async routeToLinkToPost() {
            this.isRoutingToLinkToPost = true;
            await this.$router.push(this.directRouterLinkToPost);
        },
    },
};
</script>

<style lang="scss">
.load-more-buttons {
    display: flex;
    gap: 1rem;
}

.single-view-post {
    max-width: 44rem;
}

.post-comment-show-parents {
    font-size: 14px;

    .button {
        padding: 2px 8px;
        border-radius: 3px;
        background: var(--low-contrast, #{$light-grey});
        color: var(--highest-contrast, #{$dark-grey});

        .spinner-container {
            position: absolute;
            left: 0;
        }
    }
}
</style>
