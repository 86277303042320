<template>
    <div
        class="page"
    >
        <div class="container padded">
            <LoadingWrapper :is-loading="loading">
                <div
                    v-if="post && !isDeleted"
                    class="post-route"
                >
                    <h1>{{ $t("post") }}</h1>
                    <SingleViewPost :post="post"/>
                </div>
                <div v-else-if="isDeleted">
                    <SystemMessage
                        :title="$t('you_have_just_deleted_this_post')"
                        type="maintenance"
                    >
                        {{ $t("use_the_menu_to_go_somewhere_else") }}
                    </SystemMessage>
                </div>
                <div v-else-if="isNotFound">
                    <SystemMessage
                        :title="$t('post_or_comment_not_found')"
                        type="unknown"
                    >
                        {{ $t("use_the_menu_to_go_somewhere_else") }}
                    </SystemMessage>
                </div>
                <div v-else-if="isUnknownError">
                    <SystemMessage
                        :title="$t('post_or_comment_unknown_error')"
                        type="unknown"
                    >
                        {{ $t("use_the_menu_to_go_somewhere_else") }}
                    </SystemMessage>
                </div>
            </LoadingWrapper>
        </div>
    </div>
</template>

<script>
import {
    mapActions,
    mapState,
} from "vuex";
import { POST_MODULE_NAME } from "@web/store/post/post";
import { SINGLE_POST_MODULE_NAME } from "@web/store/post/single-post/single-post";
import {
    CLEAR_STATE,
    INIT_SINGLE_COMMENT_VIEW,
    INIT_SINGLE_POST_VIEW,
    SWITCH_FROM_COMMENT_TO_POST_VIEW,
} from "@web/store/post/single-post/actions";
import SingleViewPost from "@web/views/posts/SingleViewPost";

/**
 * handles direct access to a post or comment via directUrl
 */
export default {
    name: "PostRoute",
    components: {
        SingleViewPost,
    },
    data() {
        return {
            loading: false,
            isNotFound: false,
            isUnknownError: false,
        };
    },
    computed: {
        ...mapState(POST_MODULE_NAME + SINGLE_POST_MODULE_NAME, [
            "post",
            "isDeleted",
        ]),
    },
    methods: {
        ...mapActions({
            initialSinglePostFetch: POST_MODULE_NAME + SINGLE_POST_MODULE_NAME + INIT_SINGLE_POST_VIEW,
            initialSingleCommentView: POST_MODULE_NAME + SINGLE_POST_MODULE_NAME + INIT_SINGLE_COMMENT_VIEW,
            switchFromCommentToPostView: POST_MODULE_NAME + SINGLE_POST_MODULE_NAME + SWITCH_FROM_COMMENT_TO_POST_VIEW,
            clearState: POST_MODULE_NAME + SINGLE_POST_MODULE_NAME + CLEAR_STATE,
        }),
        async fetchPostOrComment({ postUid, commentPath }) {
            this.loading = true;
            this.isNotFound = false;
            this.isUnknownError = false;
            try {
                if (!commentPath) {
                    await this.initialSinglePostFetch({ postUid });
                } else {
                    await this.initialSingleCommentView({ postUid, commentPath });
                }
            } catch ({ response }) {
                const { status } = response;
                if (status === 404) {
                    this.isNotFound = true;
                    return;
                }
                this.isUnknownError = true;
            } finally {
                this.loading = false;
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => vm.fetchPostOrComment({
            postUid: to.params.postUid,
            commentPath: to.params.commentPath,
        }));
    },
    beforeRouteUpdate(to, from, next) {
        const switchesToPostView = to.params.intranetUid === from.params.intranetUid && to.params.postUid === from.params.postUid && to.params.commentPath === undefined && from.params.commentPath !== undefined;
        if (switchesToPostView) {
            this.switchFromCommentToPostView();
        } else {
            this.fetchPostOrComment({
                postUid: to.params.postUid,
                commentPath: to.params.commentPath,
            });
        }
        next();
    },
    beforeRouteLeave(to, from, next) {
        this.clearState();
        next();
    },
};
</script>

<style lang="scss" scoped>
</style>
